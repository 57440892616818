<template>
    <div>
        <h2>
            {{ loading ? 'Cargando...' : course.title }}
        </h2>

        <div class="w-full flex flex-wrap">
            <template v-for="course in fixedPrelations">
                <a-badge
                    :key="course.id"
                    v-tooltip="`Presione para agregar a la lista por eliminar`"
                    class="cursor-pointer"
                    :style="{ background: course.specialization.color, color: course.specialization.text_color }" @click="dettach(course.id)">
                    {{ course.title }}
                </a-badge>
            </template>
        </div>

        <div class="w-full flex flex-wrap mt-4" v-if="dettached.length">
            <h4>Se eliminaran al guardar:</h4>
            <template v-for="(course, key) in deletingPrelations">
                <a-badge
                    :key="course.id"
                    v-tooltip="`Presione para remover de la lista por eliminar`"
                    class="cursor-pointer"
                    :style="{ background: course.specialization.color, color: course.specialization.text_color }" @click="undoDettach(key)">
                    {{ course.title }}
                </a-badge>
            </template>
        </div>

        <a-button class="mt-4" @click="handleDeletePrelations" v-if="dettached.length">
            Guardar cambios
        </a-button>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
    data: () => ({
        course: { title: 'Sin resultados' },
        dettached: [],
        originalData: {},
    }),
    computed: {
        ...mapState({
            prelations: ({ prelations }) => prelations.all,
            loading: ({ prelations }) => prelations.loading
        }),
        fixedPrelations() {
            return this.prelations.map($0 => ({
                ...$0.target_course,
                specialization: $0.specialization
            }))
        },
        deletingPrelations() {
            return this.fixedPrelations.filter($0 => this.dettached.some($1 => $1 === $0.id))
        },
        finalPrelations() {
            return this.fixedPrelations.filter($0 => this.dettached.some($1 => $1 !== $0.id))
        },
        semesterId() {
            return this.originalData?.pensum?.semesters?.find($0 => $0.number === this.originalData?.originating_semester_number)?.id
        },
    },
    methods: {
        ...mapActions({
            fetchPrelation: 'prelations/fetch',
            deletePrelations: 'prelations/delete'
        }),
        dettach(id) {
            this.dettached.push(id)
        },
        undoDettach(index) {
            this.dettached.splice(index, 1)
        },
        handleDeletePrelations() {

            this.deletePrelations({
                originating_course_id: this.originalData.originating_course.id,
                period_id: this.originalData.period_id,
                semester_id: this.semesterId,
                target_courses_ids: this.dettached,
            }).then(() => {
                this.$router.back()
            })
        }
    },
    mounted() {
        this.fetchPrelation({ id: this.$route.params.course, filters: { limit: 0, with: 'originatingCourse,targetCourse,specialization,pensum.semesters' } })
            .then(res => {
                const originating_course = res.data.find($0 => $0.originating_course_id == this.$route.params.course)
                this.originalData = originating_course
                this.course = originating_course?.originating_course
            })
    }
}
</script>